import { gql } from '@apollo/client';

import { request } from '..';
import { API_URL } from '../utils';

import LeadModel from 'OK/models/lead';

export const getSalesDashboardCampaignListQuery = gql`
  query GetSalesDashboardCampaignList {
    campaigns: getSalesDashboardCampaignList
  }
`;

export const getSalesDashboardIndustryListQuery = gql`
  query GetSalesDashboardIndustryList {
    industries: getSalesDashboardIndustryList
  }
`;

export const getSalesDashboardKPIDataQuery = gql`
  query GetSalesDashboardKPIData(
    $campaigns: [String]!
    $coworkers: [String]!
    $cycle: ESalesDashboardCycle!
    $industries: [String]!
    $sizes: [String]!
  ) {
    kpiData: getSalesDashboardData(
      campaignList: $campaigns
      industryList: $industries
      salesDashboardCycle: $cycle
      sizeList: $sizes
      userList: $coworkers
    ) {
      calls
      callsMade
      callsMeetings
      callsPrevious
      callsTaken
      deals
      dealsPaid
      dealsPrevious
      dealsSigned
      dealsWon
      emails
      emailsDripped
      emailsPrevious
      emailsReceived
      emailsWritten
      leads
      leadsCadence
      leadsInitalisation
      leadsPrevious
      leadsResponse
      prospects
      prospectsAssigned
      prospectsFound
      prospectsInprocess
      prospectsPrevious
    }
  }
`;

export const getSalesDashboardSizeListQuery = gql`
  query GetSalesDashboardSizeList {
    sizes: getSalesDashboardSizeList
  }
`;

export const importLeadsMutation = gql`
  mutation ImportLeads($leads: [LeadGenerateInput]!) {
    importResults: submitLeadGenerate(leadGenerateInputList: $leads) {
      duplicateFieldList
      invalidFieldList
      leadGenerate {
        ...${LeadModel.fragmentName}
      }
      success
    }
  }
  ${LeadModel.fragment}
`;

export const importAndAssignLeadsMutation = gql`
  mutation ImportAndAssignLeads($leads: [LeadGenerateInput]!, $assigneeUserId: String!) {
    importResults: submitLeadGenerateWithAssigneeUserId(leadGenerateInputList: $leads, assigneeUserId: $assigneeUserId) {
      duplicateFieldList
      invalidFieldList
      leadGenerate {
        ...${LeadModel.fragmentName}
      }
      success
    }
  }
  ${LeadModel.fragment}
`;

export const validateLeadsQuery = gql`
  query ValidateLeads($leads: [LeadGenerateInput]!) {
    validations: checkLeadGenerate(leadGenerateInputList: $leads) {
      duplicateFieldList
      invalidFieldList
      leadGenerate {
        ...${LeadModel.fragmentName}
      }
    }
  }
  ${LeadModel.fragment}
`;

export async function downloadSolutionsBriefPDF(email) {
  const parameters = { email };

  const req = await request(`${API_URL}/utility/sales/documentation/requestSolutionsDeck`, {
    addAuthorisationHeaders: false,
    method: 'POST',
    parameters,
  });
  return req;
}
