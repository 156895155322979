import { gql } from '@apollo/client';

import countries from 'OK/assets/countries.json';
import languages from 'OK/assets/languages.json';
import industries from 'OK/assets/lead_industries.json';
import parseDomainFromURL from 'OK/util/functions/parseDomainFromURL';

const FIELD_NAME_DISPLAY = {
  name: 'name',
  email: 'email',
  phoneNumber: 'phone number',
  phoneCountryIso: '',
  linkedIn: 'link',
  jobTitle: 'job title',
  languageIso: 'language',
  countryIso: 'country',
  companyLinkedIn: 'link',
  companyName: 'company name',
  companyWebsiteURL: 'link',
  size: 'size',
  need: 'need',
  industry: 'industry',
};

const phoneCountryCodes = countries
  .map((c) => {
    return {
      isoAlpha3: c.isoAlpha3,
      phoneCode: c.phoneCode,
    };
  })
  .sort((a, b) => b.phoneCode - a.phoneCode) // Sort phone codes as numbers from largest to smallest
  .filter((country, index, arr) => arr.findIndex((c) => c.phoneCode === country.phoneCode) === index); // Only use one country per phone code.

function duplicateValueMessage(field) {
  return `A lead with this ${FIELD_NAME_DISPLAY[field]} already exists.`;
}

function ensureUrlHasProtocol(url) {
  let formattedUrl;
  if (/^http/i.test(url) === false) {
    // Add http:// prefix
    formattedUrl = `http://${url}`;
  } else {
    formattedUrl = url;
  }
  return formattedUrl;
}

function invalidValueMessage(field) {
  return `Please enter a valid ${FIELD_NAME_DISPLAY[field]}.`;
}

function parseEssentialLinkedInURL(url) {
  const match = url.match(/(https?:\/\/)?([^.]+\.)?linkedin\.com.+$/);
  if (match?.length === 3) {
    let parsedUrl = url;

    const https = match[1];
    if (https) {
      parsedUrl = parsedUrl.replace(https, '');
    }

    const subdomain = match[2];
    if (subdomain) {
      parsedUrl = parsedUrl.replace(subdomain, '');
    }

    return parsedUrl;
  }

  return url;
}

export default class LeadModel {
  static LEAD_ORGANISATION_NEED = {
    SUPPLY_CHAIN_SERVICES: 'Supply chain services',
    OWN_BRAND: 'Own brand, wholesale or retail',
    MANUFACTURING: 'Manufacturing or sourcing',
  };

  static LEAD_ORGANISATION_SIZE = {
    SMALL: 'Small',
    MEDIUM: 'Medium',
    ENTERPRISE: 'Enterprise',
  };

  static LEAD_VALIDATION_ERROR_LEVEL = {
    ERROR: 'ERROR',
    WARNING: 'WARNING',
  };

  static leadHasData(lead) {
    return (
      lead.name ||
      lead.email ||
      lead.phoneNumber ||
      lead.linkedIn ||
      lead.languageIso ||
      lead.countryIso ||
      lead.companyName ||
      lead.companyWebsiteURL ||
      lead.size ||
      lead.need ||
      lead.industry
    );
  }

  static GRAPHQL_TYPE = 'LeadGenerate';

  static fragmentName = 'Lead';
  static fragment = gql`
    fragment ${LeadModel.fragmentName} on ${LeadModel.GRAPHQL_TYPE} {
      companyLinkedIn
      companyName
      companyWebsite
      country
      countryCode
      email
      industry
      jobTitle
      language
      languageCode
      linkedIn
      name
      needsSegment
      phone
      sizeSegment
    }
  `;

  static formatToServerLead(lead) {
    const serverLead = {};
    if (lead.name) {
      serverLead.name = lead.name;
    }
    if (lead.email) {
      serverLead.email = lead.email;
    }
    if (lead.phoneCountryIso && lead.phoneNumber) {
      const parsedPhoneNumber = lead.phoneNumber.replace(/\D/g, '');
      serverLead.phone = `+${
        countries.find((c) => c.isoAlpha3 === lead.phoneCountryIso).phoneCode
      }${parsedPhoneNumber}`;
    }
    if (lead.linkedIn) {
      serverLead.linkedIn = ensureUrlHasProtocol(lead.linkedIn);
    }
    if (lead.jobTitle) {
      serverLead.jobTitle = lead.jobTitle;
    }
    if (lead.languageIso) {
      serverLead.language = languages.find((lang) => lang.iso === lead.languageIso).name;
      serverLead.languageCode = lead.languageIso;
    }
    if (lead.countryIso) {
      serverLead.country = countries.find((c) => c.isoAlpha3 === lead.countryIso).name;
      serverLead.countryCode = lead.countryIso;
    }
    if (lead.companyName) {
      serverLead.companyName = lead.companyName;
    }
    if (lead.companyWebsiteURL) {
      serverLead.companyWebsite = ensureUrlHasProtocol(lead.companyWebsiteURL);
    }
    if (lead.companyLinkedIn) {
      serverLead.companyLinkedIn = ensureUrlHasProtocol(lead.companyLinkedIn);
    }
    if (lead.size) {
      serverLead.sizeSegment = lead.size;
    }
    if (lead.need) {
      serverLead.needsSegment = lead.need;
    }
    if (lead.industry) {
      serverLead.industry = lead.industry;
    }
    return serverLead;
  }

  static localFieldNameForServerFieldName(serverFieldName) {
    switch (serverFieldName) {
      case 'companyWebsite':
        return 'companyWebsiteURL';
      case 'country':
      case 'countryCode':
        return 'countryIso';
      case 'language':
      case 'languageCode':
        return 'languageIso';
      case 'needsSegment':
        return 'need';
      case 'phone':
        return 'phoneNumber';
      case 'sizeSegment':
        return 'size';
      default:
        return serverFieldName;
    }
  }

  static mergeServerAndLocalValidations = (serverValidations, localValidations) => {
    return localValidations.map((localValidation, index) => {
      const serverValidation = serverValidations[index];
      if (!serverValidation) {
        // Could not find server validation
        return localValidation;
      }

      const { duplicateFieldList, invalidFieldList } = serverValidation;

      // Merge local and server validations
      const mergedValidation = { ...localValidation };

      invalidFieldList.forEach((invalidField) => {
        const localFieldName = LeadModel.localFieldNameForServerFieldName(invalidField);
        const currentValidation = mergedValidation[localFieldName];
        if (!currentValidation || currentValidation.level === LeadModel.LEAD_VALIDATION_ERROR_LEVEL.WARNING) {
          // Save server validation if no local one or local one is just a warning
          mergedValidation[localFieldName] = {
            level: LeadModel.LEAD_VALIDATION_ERROR_LEVEL.ERROR,
            message: invalidValueMessage(localFieldName),
          };
        }
      });

      duplicateFieldList.forEach((duplicateField) => {
        const localFieldName = LeadModel.localFieldNameForServerFieldName(duplicateField);
        const currentValidation = mergedValidation[localFieldName];
        if (!currentValidation) {
          // Save server validation if no local one
          let validationLevel;
          switch (localFieldName) {
            case 'name':
            case 'companyName':
            case 'companyWebsiteURL':
              validationLevel = LeadModel.LEAD_VALIDATION_ERROR_LEVEL.WARNING;
              break;
            default:
              validationLevel = LeadModel.LEAD_VALIDATION_ERROR_LEVEL.ERROR;
              break;
          }
          mergedValidation[localFieldName] = {
            level: validationLevel,
            message: duplicateValueMessage(localFieldName),
          };
        }
      });

      return mergedValidation;
    });
  };

  static validateLeads = async (leads) => {
    if (!leads.length) {
      return [];
    }

    const validator = (await import('validator')).default;

    // Data
    const names = {};
    const emails = {};
    const phoneNumbers = {};
    const linkedInURLs = {};
    const companyWebsiteURLs = {};

    // Check
    let newValidations = [];
    for (const leadIndex in leads) {
      const lead = leads[leadIndex];
      const validation = {};

      if (!lead.name) {
        // Error for missing name
        validation.name = {
          level: LeadModel.LEAD_VALIDATION_ERROR_LEVEL.ERROR,
          message: 'Name is required.',
        };
      } else {
        // Record names for duplicate check later
        if (typeof names[lead.name] === 'undefined') {
          names[lead.name] = 1;
        } else {
          names[lead.name] += 1;
        }
      }

      if (!lead.email && !lead.phoneNumber && !lead.linkedIn) {
        // Error if not at least one of: email, phone, LinkedIn
        validation.email = {
          level: LeadModel.LEAD_VALIDATION_ERROR_LEVEL.ERROR,
          message: 'Email, phone, or LinkedIn is required.',
        };
        validation.phoneNumber = {
          level: LeadModel.LEAD_VALIDATION_ERROR_LEVEL.ERROR,
          message: 'Email, phone, or LinkedIn is required.',
        };
        validation.linkedIn = {
          level: LeadModel.LEAD_VALIDATION_ERROR_LEVEL.ERROR,
          message: 'Email, phone, or LinkedIn is required.',
        };
      } else {
        if (lead.email) {
          if (!validator.isEmail(lead.email)) {
            // Error for invalid email
            validation.email = {
              level: LeadModel.LEAD_VALIDATION_ERROR_LEVEL.ERROR,
              message: invalidValueMessage('email'),
            };
          } else {
            // Record emails for duplicate check later
            if (typeof emails[lead.email] === 'undefined') {
              emails[lead.email] = 1;
            } else {
              emails[lead.email] += 1;
            }
          }
        }
        if (lead.phoneNumber) {
          if (!lead.phoneCountryIso) {
            // Error for no country phone code
            validation.phoneCountryIso = {
              level: LeadModel.LEAD_VALIDATION_ERROR_LEVEL.ERROR,
              message: 'Phone numbers require country code.',
            };
          } else if (!validator.isMobilePhone(lead.phoneNumber)) {
            // Error for invalid phone
            validation.phoneNumber = {
              level: LeadModel.LEAD_VALIDATION_ERROR_LEVEL.ERROR,
              message: invalidValueMessage('phoneNumber'),
            };
          } else {
            // Record phone numbers for duplicate check later
            const fullPhoneNumber = `${lead.phoneCountryIso}${lead.phoneNumber}`;
            if (typeof phoneNumbers[fullPhoneNumber] === 'undefined') {
              phoneNumbers[fullPhoneNumber] = 1;
            } else {
              phoneNumbers[fullPhoneNumber] += 1;
            }
          }
        }
      }

      if (lead.linkedIn) {
        if (!validator.isURL(lead.linkedIn)) {
          // Error for invalid LinkedIn
          validation.linkedIn = {
            level: LeadModel.LEAD_VALIDATION_ERROR_LEVEL.ERROR,
            message: invalidValueMessage('linkedIn'),
          };
        } else {
          // Record LinkedIn URLs for duplicate check later
          const parsedLinkedInURL = parseEssentialLinkedInURL(lead.linkedIn);
          if (typeof linkedInURLs[parsedLinkedInURL] === 'undefined') {
            linkedInURLs[parsedLinkedInURL] = 1;
          } else {
            linkedInURLs[parsedLinkedInURL] += 1;
          }
        }
      }

      if (!lead.countryIso) {
        validation.countryIso = {
          level: LeadModel.LEAD_VALIDATION_ERROR_LEVEL.ERROR,
          message: 'Place is required.',
        };
      }

      if (!lead.companyName) {
        // Error for missing company name
        validation.companyName = {
          level: LeadModel.LEAD_VALIDATION_ERROR_LEVEL.ERROR,
          message: 'Company name is required.',
        };
      }

      if (!lead.companyWebsiteURL) {
        validation.companyWebsiteURL = {
          level: LeadModel.LEAD_VALIDATION_ERROR_LEVEL.ERROR,
          message: 'Company website is required.',
        };
      } else {
        if (!validator.isURL(lead.companyWebsiteURL)) {
          // Error for invalid company website
          validation.companyWebsiteURL = {
            level: LeadModel.LEAD_VALIDATION_ERROR_LEVEL.ERROR,
            message: invalidValueMessage('companyWebsiteURL'),
          };
        } else {
          const domain = parseDomainFromURL(lead.companyWebsiteURL);
          if (typeof companyWebsiteURLs[domain] === 'undefined') {
            companyWebsiteURLs[domain] = 1;
          } else {
            companyWebsiteURLs[domain] += 1;
          }
        }
      }

      if (lead.companyLinkedIn) {
        if (!validator.isURL(lead.companyLinkedIn)) {
          // Error for invalid company LinkedIn
          validation.companyLinkedIn = {
            level: LeadModel.LEAD_VALIDATION_ERROR_LEVEL.ERROR,
            message: invalidValueMessage('companyLinkedIn'),
          };
        }
      }

      newValidations.push(validation);
    }

    // Set errors / warnings for duplicate data
    for (const leadIndex in leads) {
      const lead = leads[leadIndex];
      if (lead.name && names[lead.name] > 1) {
        // Warn for duplicate names
        newValidations[leadIndex].name = {
          level: LeadModel.LEAD_VALIDATION_ERROR_LEVEL.WARNING,
          message: duplicateValueMessage('name'),
        };
      }
      if (lead.email && emails[lead.email] > 1) {
        // Error for duplicate email
        newValidations[leadIndex].email = {
          level: LeadModel.LEAD_VALIDATION_ERROR_LEVEL.ERROR,
          message: duplicateValueMessage('email'),
        };
      }
      if (lead.phoneNumber && phoneNumbers[`${lead.phoneCountryIso}${lead.phoneNumber}`] > 1) {
        // Error for duplicate phone number
        newValidations[leadIndex].phoneNumber = {
          level: LeadModel.LEAD_VALIDATION_ERROR_LEVEL.ERROR,
          message: duplicateValueMessage('phoneNumber'),
        };
      }
      if (lead.linkedIn && linkedInURLs[parseEssentialLinkedInURL(lead.linkedIn)] > 1) {
        // Error for duplicate LinkedIn
        newValidations[leadIndex].linkedIn = {
          level: LeadModel.LEAD_VALIDATION_ERROR_LEVEL.ERROR,
          message: duplicateValueMessage('linkedIn'),
        };
      }
      if (lead.companyWebsiteURL && companyWebsiteURLs[parseDomainFromURL(lead.companyWebsiteURL)] > 1) {
        // Warning for duplicate company website
        newValidations[leadIndex].companyWebsiteURL = {
          level: LeadModel.LEAD_VALIDATION_ERROR_LEVEL.WARNING,
          message: duplicateValueMessage('companyWebsiteURL'),
        };
      }
    }

    return newValidations;
  };

  constructor(data = {}) {
    // Parse name
    let name;
    if (data.name) {
      name = data.name;
    } else if (data.firstname || data.lastname) {
      if (data.firstname && data.lastname) {
        name = `${data.firstname} ${data.lastname}`;
      } else if (data.firstname) {
        name = data.firstname;
      } else {
        name = data.lastname;
      }
    }

    // Parse phone number
    let phoneCountryIso;
    let phoneNumber;
    if (data.phone || data.firstphone) {
      const phone = data.phone ?? data.firstphone;
      // Parse country code
      const phoneNumsOnly = phone.replace(/\D/g, '');
      for (let x = 0; x < phoneCountryCodes.length; x++) {
        const country = phoneCountryCodes[x];
        const countryPhoneCode = country.phoneCode;
        const countryCodeRegex = new RegExp(`^${countryPhoneCode}`);
        const phoneCountryCodeMatch = phoneNumsOnly.match(countryCodeRegex);
        if (phoneCountryCodeMatch?.length > 0) {
          phoneCountryIso = country.isoAlpha3;
          phoneNumber = phoneNumsOnly.replace(`${countryPhoneCode}`, '');
          break;
        }
      }

      if (!phoneNumber) {
        // No country code found, so use number as-is.
        phoneNumber = phoneNumsOnly;
      }
    }

    // Parse LinkedIn url
    const linkedIn = data.linkedin ?? data.personlinkedinurl;

    // Parse job title
    const jobTitle = data.jobtitle ?? data.title;

    // Parse language
    let languageIso;
    if (data.language) {
      const language = languages.find((l) => {
        const parsedLanguage = data.language.toUpperCase();
        return (
          l.iso === parsedLanguage ||
          l.name.toUpperCase() === parsedLanguage ||
          l.nativeName.toUpperCase() === parsedLanguage
        );
      });
      if (language) {
        languageIso = language.iso;
      }
    }

    // Parse country
    let countryIso;
    if (data.country) {
      const country = countries.find((c) => {
        const parsedCountry = data.country.toUpperCase();
        return (
          c.isoAlpha3 === parsedCountry ||
          c.name.toUpperCase() === parsedCountry ||
          c.nativeName.toUpperCase() === parsedCountry ||
          c.alternativeNames?.findIndex((n) => n.toUpperCase() === parsedCountry) > -1
        );
      });
      if (country) {
        countryIso = country.isoAlpha3;
      }
    }

    // Parse company LinkedIn
    const companyLinkedIn = data.companylinkedin || data.companylinkedinurl;

    // Parse size
    let size;
    if (data.size) {
      size = LeadModel.LEAD_ORGANISATION_SIZE[data.size.toUpperCase()];
    }

    // Parse need
    let need;
    if (data.need) {
      switch (data.need) {
        case LeadModel.LEAD_ORGANISATION_NEED.SUPPLY_CHAIN_SERVICES:
        case LeadModel.LEAD_ORGANISATION_NEED.OWN_BRAND:
        case LeadModel.LEAD_ORGANISATION_NEED.MANUFACTURING:
          need = data.need;
          break;
        default:
          break;
      }
    }

    // Parse industry
    let industry;
    if (data.industry) {
      const parsedIndustry = data.industry.toUpperCase();
      industry = industries.find((i) => i.value.toUpperCase() === parsedIndustry)?.value;
    }

    this.id = data.id;
    this.name = name ?? '';
    this.jobTitle = jobTitle ?? '';
    this.email = data.email ?? '';
    this.phoneNumber = phoneNumber ?? '';
    this.phoneCountryIso = phoneCountryIso ?? '';
    this.linkedIn = linkedIn ?? '';
    this.languageIso = languageIso ?? '';
    this.countryIso = countryIso ?? '';
    this.companyLinkedIn = companyLinkedIn ?? '';
    this.companyName = data.company ?? '';
    this.companyWebsiteURL = data.website ?? '';
    this.size = size ?? '';
    this.need = need ?? '';
    this.industry = industry ?? '';
  }
}
